import React, { Component } from 'react';
import { connect } from 'react-redux';

import SiteConstants from '../../../../common/SiteConstants/SiteConstants';
import { startCase } from '../../../../common/utils';
import { searchOrigins } from '../../../Shared/constants';
import {
  hideSubMenu,
  updateSearchOptionsAndRefresh,
} from '../../actions/SearchActions';
import AppliedFilter from '../../components/appliedFilters/AppliedFilter';
import ContentType from '../../components/appliedFilters/ContentType';
import Duration from '../../components/appliedFilters/Duration';
import ResultCount from '../../components/appliedFilters/ResultCount';
import SearchFilterOptions, {
  ALL_VIDEOS_CONTENT_TYPE,
} from '../../entities/SearchFilterOptions';
import { UsageRights } from '../../entities/UsageRights';
import {
  categoryTypes,
  frameRates as frameRateOptions,
  keepOnlyCategoryIds,
  keepOnlyTemplateTypeIds,
  templateTypes,
} from '../../entities/VideoSearchFilterOptions';
import {
  selectPagination,
  selectSearchFeatures,
  selectSearchFilterOptions,
  selectSearchIsLoading,
  selectShouldUpdateSearchFilters,
  selectSuggestionsQuery,
} from '../../selectors/searchSelectors';
import { getOptionLabel } from './AppliedFiltersUtils';

import './AppliedFilters.less';

class VideoAppliedFilters extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.shouldUpdateSearchFilters !== false;
  }

  hasAppliedFilters = () => {
    const {
      searchTerm,
      contentType,
      templateType,
      categories,
      videoQuality,
      frameRates,
      minDuration,
      maxDuration,
      talentReleased,
      propertyReleased,
      usageRights,
    } = this.props.selectedSearchFilterOptions;
    return (
      searchTerm ||
      contentType !== 'all-videos' ||
      templateType ||
      categories ||
      videoQuality !== 'HD' ||
      frameRates.length > 0 ||
      minDuration ||
      maxDuration ||
      talentReleased ||
      propertyReleased ||
      (usageRights !== UsageRights.All && usageRights)
    );
  };

  clearAllFilters = () => {
    const resetFilters = {
      searchTerm: '',
      contentType: 'all-videos',
      templateType: '',
      categories: '',
      videoQuality: 'HD',
      frameRates: [],
      minDuration: null,
      maxDuration: null,
      talentReleased: false,
      propertyReleased: false,
      usageRights: null,
    };

    this.updateFilters(resetFilters);

    this.setState({ searchTerm: '' });
  };

  renderClearFiltersButton() {
    return (
      <button
        className="clear-all-btn text-xs underline border-none bg-none text-black cursor-pointer mr-2.5"
        onClick={this.clearAllFilters}
      >
        Clear All
      </button>
    );
  }
  renderNew() {
    const filtersApplied = this.hasAppliedFilters();
    const className = 'applied-filters md:flex flex md:flex-wrap';

    return (
      <div
        className={`flex w-full ${
          filtersApplied ? 'justify-between' : 'justify-end'
        }`}
      >
        {filtersApplied && (
          <div className={className}>
            {this.renderSearchTerm()}
            {this.renderContentType()}
            {this.renderTemplateType()}
            {this.renderResolution()}
            {this.renderFrameRate()}
            {this.renderDuration()}
            {this.renderMediaDetails()}
            {this.renderUsageRights()}
            {this.renderCategory()}
            {this.renderClearFiltersButton()}
          </div>
        )}
      </div>
    );
  }

  render() {
    if (this.props.features.useRemainingSearchUI) {
      return this.renderNew();
    }

    const className = 'applied-filters md:flex flex flex-nowrap md:flex-wrap';

    return (
      <div className={className}>
        {this.renderSearchTerm()}
        {this.renderContentType()}
        {this.renderTemplateType()}
        {this.renderResolution()}
        {this.renderFrameRate()}
        {this.renderDuration()}
        {this.renderMediaDetails()}
        {this.renderUsageRights()}
        {this.renderCategory()}
        {this.renderResultCount()}
      </div>
    );
  }

  renderSearchTerm(
    className = '',
    backgroundClassName = '',
    closeButtonClassName = ''
  ) {
    const { searchTerm } = this.props.selectedSearchFilterOptions;
    const suggestionsQuery = this.props.selectedSuggestionsQuery;

    const displaySearchTerm = suggestionsQuery ? suggestionsQuery : searchTerm;

    if (displaySearchTerm) {
      const label = `"${displaySearchTerm}"`;
      return (
        <AppliedFilter
          label={label}
          onClear={() => {
            this.updateFilters({ searchTerm: '' });
          }}
          className={className}
          backgroundClassName={backgroundClassName}
          closeButtonClassName={closeButtonClassName}
        />
      );
    } else {
      return null;
    }
  }

  renderContentType() {
    const { contentType } = this.props.selectedSearchFilterOptions;

    if (contentType === ALL_VIDEOS_CONTENT_TYPE) {
      return null;
    } else {
      return (
        <ContentType
          appliedContentType={contentType}
          allContentTypes={SearchFilterOptions.getContentTypes()}
          onClear={() =>
            this.updateFilters({
              contentType: ALL_VIDEOS_CONTENT_TYPE,
            })
          }
        />
      );
    }
  }

  renderTemplateType() {
    const { contentType, categories } = this.props.selectedSearchFilterOptions;

    if (contentType !== 'templates' || !categories) {
      return null;
    }

    return categories
      .split(',')
      .map((idString) => {
        const label = getOptionLabel(idString, templateTypes);
        if (label && label !== 'All Templates') {
          return (
            <AppliedFilter
              label={label}
              onClear={() => {
                this.updateFilters({
                  templateType: '',
                  categories: keepOnlyCategoryIds(categories),
                });
              }}
            />
          );
        }
      })
      .filter(Boolean)[0];
  }

  renderResolution() {
    const { videoQuality } = this.props.selectedSearchFilterOptions;

    if (videoQuality === 'HD') {
      return null;
    } else {
      return (
        <AppliedFilter
          label={videoQuality}
          onClear={() => {
            this.updateFilters({ videoQuality: 'HD' });
          }}
        />
      );
    }
  }

  renderFrameRate() {
    const { frameRates: selectedFrameRates } =
      this.props.selectedSearchFilterOptions;

    const optionNameToLabelMap = frameRateOptions.reduce((map, option) => {
      map[option.name] = option.label;
      return map;
    }, {});

    const appliedFrameRateFilters = (selectedFrameRates || []).map(
      (frameRateName) => (
        <AppliedFilter
          label={`Frame Rate: ${optionNameToLabelMap[frameRateName]}`}
          onClear={() =>
            this.updateFilters({
              frameRates: selectedFrameRates.filter(
                (frameRate) => frameRate !== frameRateName
              ),
            })
          }
        />
      )
    );

    return appliedFrameRateFilters.length ? appliedFrameRateFilters : null;
  }

  renderDuration() {
    const { minDuration, maxDuration } = this.props.selectedSearchFilterOptions;

    return (
      <Duration
        minDuration={minDuration}
        maxDuration={maxDuration}
        onClear={() =>
          this.updateFilters({ minDuration: null, maxDuration: null })
        }
      />
    );
  }

  renderMediaDetails() {
    const { talentReleased, propertyReleased } =
      this.props.selectedSearchFilterOptions;

    return (
      <React.Fragment>
        {talentReleased ? (
          <AppliedFilter
            label="Model Released"
            onClear={() => {
              this.updateFilters({ talentReleased: false });
            }}
          />
        ) : null}

        {propertyReleased ? (
          <AppliedFilter
            label="Property Released"
            onClear={() => {
              this.updateFilters({ propertyReleased: false });
            }}
          />
        ) : null}
      </React.Fragment>
    );
  }

  renderUsageRights() {
    const { usageRights } = this.props.selectedSearchFilterOptions;

    if (!usageRights || usageRights === UsageRights.All) {
      return null;
    }

    const label = startCase(usageRights);
    const onClear = () => this.updateFilters({ usageRights: UsageRights.All });

    return <AppliedFilter label={label} onClear={onClear} />;
  }

  renderCategory() {
    const { contentType, categories } = this.props.selectedSearchFilterOptions;

    if (!categories || contentType === ALL_VIDEOS_CONTENT_TYPE) {
      return null;
    }

    return categories
      .split(',')
      .map((idString) => {
        const label = getOptionLabel(idString, categoryTypes);
        if (label && label !== 'All Categories') {
          return (
            <AppliedFilter
              label={label}
              onClear={() => {
                this.updateFilters({
                  categories: keepOnlyTemplateTypeIds(categories),
                });
              }}
            />
          );
        }
      })
      .filter(Boolean)[0];
  }

  renderResultCount(className = '') {
    const { numberOfResults } = this.props;

    if (this.shouldHideResultCount()) {
      return null;
    } else {
      return (
        <ResultCount numberOfResults={numberOfResults} className={className} />
      );
    }
  }

  shouldHideResultCount() {
    const { isLoading, selectedSearchFilterOptions } = this.props;

    if (isLoading || !selectedSearchFilterOptions) {
      return true;
    } else {
      const {
        contentType,
        searchTerm,
        videoQuality,
        minDuration,
        maxDuration,
        talentReleased,
        propertyReleased,
      } = selectedSearchFilterOptions;

      return (
        contentType === ALL_VIDEOS_CONTENT_TYPE &&
        !searchTerm &&
        videoQuality === 'HD' &&
        minDuration === SiteConstants.getInstance().getMinDuration() &&
        maxDuration === SiteConstants.getInstance().getMaxDuration() &&
        !talentReleased &&
        !propertyReleased
      );
    }
  }

  updateFilters = (properties) => {
    const contentTypeDidChange =
      properties.contentType &&
      properties.contentType !==
        this.props.selectedSearchFilterOptions.contentType;

    const propertiesToChange = {
      page: 1,
      searchOrigin: searchOrigins.APPLIED_FILTERS,
      isPagination: false,
      ...properties,
    };

    if (contentTypeDidChange) {
      propertiesToChange.categories = '';
    }

    const newSearchOptions =
      this.props.selectedSearchFilterOptions.update(propertiesToChange);
    this.props.dispatch(updateSearchOptionsAndRefresh(newSearchOptions));
    contentTypeDidChange && this.props.dispatch(hideSubMenu());
  };
}

function mapStateToProps(state) {
  const pagination = selectPagination(state);

  return {
    selectedSearchFilterOptions: selectSearchFilterOptions(state),
    shouldUpdateSearchFilters: selectShouldUpdateSearchFilters(state),
    numberOfResults: pagination.totalResults,
    isLoading: selectSearchIsLoading(state),
    features: selectSearchFeatures(state),
    selectedSuggestionsQuery: selectSuggestionsQuery(state),
  };
}

export default connect(mapStateToProps)(VideoAppliedFilters);
