import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { initiateFetchWorkflow } from '../actions/SearchActions';
import SearchOptions from '../entities/SearchOptions';
import useVideoSearchOptionsFromLocation from '../hooks/useVideoSearchOptionsFromLocation';
import {
  calculateAppliedFilterNums,
  videoSearchContentTypeToKeys,
} from '../utils/searchUtils';
import SearchContainer from './SearchContainer';
import VideoMenuContainer from './VideoMenuContainer';
import VideoAppliedFilters from './appliedFilters/VideoAppliedFilters';

const VideoSearchContainer = () => {
  const searchOptions = useVideoSearchOptionsFromLocation();
  const searchOptionsRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchOptionsRef.current) {
      dispatch(initiateFetchWorkflow(searchOptions));
    }
    searchOptionsRef.current = searchOptions;
  }, [searchOptions]);

  const getFilterTotal = (selectedSearchFilterOptions) => {
    return calculateAppliedFilterNums(
      selectedSearchFilterOptions,
      videoSearchContentTypeToKeys,
      SearchOptions.getDefaultSearchOptions()
    );
  };

  return (
    <SearchContainer
      renderAppliedFilters={() => <VideoAppliedFilters />}
      menuContainer={VideoMenuContainer}
      calculateAppliedFilterNums={getFilterTotal}
    />
  );
};

export default VideoSearchContainer;
