import React, { Component } from 'react';
import { connect } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { searchOrigins } from '../../Shared/constants';
import { SearchFeatures } from '../SearchTypes';
import {
  collapseMoreLikeThisDrawer,
  updateSearchOptionsAndRefresh,
} from '../actions/SearchActions';
import ToggleMenuButton from '../components/ToggleMenuButton';
import ClearFilters from '../components/filters/ClearFilters';
import CollapsibleCheckboxFilter from '../components/filters/CollapsibleCheckboxFilter';
import CollapsibleRadioFilter from '../components/filters/CollapsibleRadioFilter';
import DurationSliderFilter from '../components/filters/DurationSliderFilter';
import SearchFilterOptions, {
  ALL_VIDEOS_CONTENT_TYPE,
} from '../entities/SearchFilterOptions';
import SearchOptions from '../entities/SearchOptions';
import { UsageRights, UsageRightsTooltip } from '../entities/UsageRights';
import {
  categoryTypes,
  frameRates as frameRateOptions,
  getCategoriesForContentType,
  keepOnlyCategoryIds,
  mediaDetails as mediaDetailsOptions,
  qualities as VideoQualities,
  templateTypes,
} from '../entities/VideoSearchFilterOptions';
import {
  selectSearchFeatures,
  selectSearchFilterOptions,
  selectShouldUpdateSearchFilters,
} from '../selectors/searchSelectors';
import {
  VideoQualityType,
  VideoSelectedSearchFilterOptions,
} from './MenuContainerInterfaces';

type Props = {
  shouldOpenSideMenu: boolean;
  selectedSearchFilterOptions: VideoSelectedSearchFilterOptions;
  shouldUpdateSearchFilters: boolean;
  features: SearchFeatures;
  closeSideMenu: () => void;
  showSideMenu: () => void;
  updateSearchOptionsAndRefresh: typeof updateSearchOptionsAndRefresh;
  collapseMoreLikeThisDrawer: typeof collapseMoreLikeThisDrawer;
};

type State = {
  collapsed: {
    contentType: boolean;
    templateType: boolean;
    videoQuality: boolean;
    duration: boolean;
    mediaDetails: boolean;
    categories: boolean;
    frameRates: boolean;
    usageRights: boolean;
  };
};

class VideoMenuContainer extends Component<Props, State> {
  state = {
    collapsed: {
      contentType: false,
      templateType: false,
      videoQuality: false,
      duration: false,
      mediaDetails: false,
      categories: false,
      frameRates: false,
      usageRights: false,
    },
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.shouldUpdateSearchFilters !== false;
  }

  render() {
    const closeSideMenuClass = this.props.features.useRemainingSearchUI
      ? 'close-side-menu w-0'
      : 'close-side-menu';
    const sideMenuClass = twMerge(
      this.props.features.useRemainingSearchUI ? '' : 'search-ui-border ',
      'videoblocks side-menu block',
      this.props.shouldOpenSideMenu ? 'open-side-menu' : closeSideMenuClass
    );

    return (
      <div className={sideMenuClass}>
        <div className="side-menu-stickyContainer">
          <ClearFilters
            isOpen={this.props.shouldOpenSideMenu}
            closeSideMenu={this.props.closeSideMenu}
            showSideMenu={this.props.showSideMenu}
            clearFilters={this.clearFilters}
          />
          <div className="side-menu-filters">
            {this.renderMediaTypeFilter()}

            {this.renderTemplateTypeFilter()}

            {this.renderResolutionFilter()}

            {this.renderFrameRateFilter()}

            {this.renderDurationFilter()}

            {this.renderMediaDetailsFilter()}

            {this.renderUsageRightsFilter()}

            {this.renderCategoryFilter()}
          </div>
        </div>
      </div>
    );
  }

  renderToggleMenuButton() {
    return (
      <ToggleMenuButton
        isOpen={this.props.shouldOpenSideMenu}
        closeSideMenu={this.props.closeSideMenu}
        showSideMenu={this.props.showSideMenu}
      />
    );
  }

  renderMediaTypeFilter() {
    //This is displayed as "Media Type" on the FE
    return (
      <>
        <CollapsibleRadioFilter
          checked={this.props.selectedSearchFilterOptions.contentType}
          filterName="contentType"
          filterValues={SearchFilterOptions.getContentTypes()}
          handleFilterChanged={this.handleFilterChanged}
          name="Media Type"
          isCollapsed={this.state.collapsed.contentType}
          toggleCollapsed={() => this.toggleCollapsed('contentType')}
        />
      </>
    );
  }

  renderTemplateTypeFilter() {
    if (this.props.selectedSearchFilterOptions.contentType === 'templates') {
      return (
        <>
          <CollapsibleRadioFilter
            checked={this.getCurrentTemplateValue(
              this.props.selectedSearchFilterOptions.categories
            )}
            filterName="templateType"
            filterValues={SearchFilterOptions.getTemplateTypes()}
            handleFilterChanged={this.handleFilterChanged}
            name="Template Type"
            isCollapsed={this.state.collapsed.templateType}
            toggleCollapsed={() => this.toggleCollapsed('templateType')}
          />
        </>
      );
    }
  }

  renderResolutionFilter() {
    return (
      <>
        <CollapsibleRadioFilter
          name="Resolution"
          handleFilterChanged={this.handleFilterChanged}
          filterName="videoQuality"
          filterValues={VideoQualities}
          checked={this.props.selectedSearchFilterOptions.videoQuality}
          isCollapsed={this.state.collapsed.videoQuality}
          toggleCollapsed={() => this.toggleCollapsed('videoQuality')}
        />
      </>
    );
  }

  renderFrameRateFilter() {
    const { frameRates } = this.props.selectedSearchFilterOptions;

    return (
      <>
        <CollapsibleCheckboxFilter
          name="Frame Rate"
          groupId="FrameRate"
          handleFilterChanged={this.handleFilterChanged}
          options={frameRateOptions}
          checkedValues={frameRates}
          isCollapsed={this.state.collapsed.frameRates}
          toggleCollapsed={() => this.toggleCollapsed('frameRates')}
        />
      </>
    );
  }

  renderDurationFilter() {
    return (
      <>
        <DurationSliderFilter
          minRangeValue={0}
          maxRangeValue={60}
          stepInterval={1}
          name="Duration"
          isCollapsed={this.state.collapsed.duration}
          toggleCollapsed={() => this.toggleCollapsed('duration')}
        />
      </>
    );
  }

  renderMediaDetailsFilter() {
    const selectedMediaDetails = this.getSelectedMediaDetails(
      this.props.selectedSearchFilterOptions
    );

    return (
      <>
        <CollapsibleCheckboxFilter
          name="Media Details"
          groupId="MediaDetails"
          handleFilterChanged={this.handleFilterChanged}
          options={mediaDetailsOptions}
          checkedValues={selectedMediaDetails}
          isCollapsed={this.state.collapsed.mediaDetails}
          toggleCollapsed={() => this.toggleCollapsed('mediaDetails')}
        />
      </>
    );
  }

  renderUsageRightsFilter() {
    const selectedUsageRights =
      this.props.selectedSearchFilterOptions.usageRights ?? UsageRights.All;

    const allOptions = [
      {
        label: 'All usage rights',
        value: UsageRights.All,
      },
      {
        label: 'Commercial',
        value: UsageRights.Commercial,
        tooltip: UsageRightsTooltip.Commercial,
      },
      {
        label: 'Editorial',
        value: UsageRights.Editorial,
        tooltip: UsageRightsTooltip.Editorial,
      },
    ];

    return (
      <>
        <CollapsibleRadioFilter
          name="Usage Rights"
          filterName="usageRights"
          filterValues={allOptions}
          checked={selectedUsageRights}
          isCollapsed={this.state.collapsed.usageRights}
          toggleCollapsed={() => this.toggleCollapsed('usageRights')}
          alphabetizeOptions={false}
          handleFilterChanged={this.handleFilterChanged}
        />
      </>
    );
  }

  renderCategoryFilter() {
    const { selectedSearchFilterOptions } = this.props;
    const isAllVideo =
      selectedSearchFilterOptions.contentType === ALL_VIDEOS_CONTENT_TYPE;

    return (
      <>
        <CollapsibleRadioFilter
          name="Categories"
          handleFilterChanged={this.handleFilterChanged}
          filterName="categories"
          filterValues={getCategoriesForContentType(
            selectedSearchFilterOptions.contentType
          )}
          checked={this.getCurrentCategoryValue(
            selectedSearchFilterOptions.categories
          )}
          isCollapsed={this.state.collapsed.categories}
          toggleCollapsed={() => this.toggleCollapsed('categories')}
          invalidSelectionMessage={
            isAllVideo ? 'Select Media Type to see Categories' : null
          }
        />
      </>
    );
  }

  getSelectedMediaDetails(selectedSearchFilterOptions) {
    const selectedMediaDetails = [];

    if (selectedSearchFilterOptions.propertyReleased) {
      selectedMediaDetails.push('propertyReleased');
    }

    if (selectedSearchFilterOptions.talentReleased) {
      selectedMediaDetails.push('talentReleased');
    }

    return selectedMediaDetails;
  }

  //Categories passed here contain template types and actual categories but stored as categoryId, need to separate to only
  //Display the category
  getCurrentCategoryValue(selectedCategories) {
    let selectedCategoriesArray;
    if (!isNaN(selectedCategories)) {
      selectedCategoriesArray = [selectedCategories.toString()];
    } else if (selectedCategories === '') {
      selectedCategoriesArray = [];
    } else {
      selectedCategoriesArray = selectedCategories.split(',');
    }

    let actualCategory;
    selectedCategoriesArray.forEach((selectedCategory) => {
      if (actualCategory === undefined) {
        actualCategory = categoryTypes.find((category) => {
          return category.value === selectedCategory;
        });
      }
    });

    return actualCategory ? actualCategory.value : '';
  }

  //Categories passed here contain template types and actual categories but stored as categoryId, need to separate to only
  //Display the category
  getCurrentTemplateValue(selectedCategories) {
    let selectedCategoriesArray;
    if (!isNaN(selectedCategories)) {
      selectedCategoriesArray = [selectedCategories.toString()];
    } else if (selectedCategories === '') {
      selectedCategoriesArray = [];
    } else {
      selectedCategoriesArray = selectedCategories.split(',');
    }

    let actualCategory;
    selectedCategoriesArray.forEach((selectedCategory) => {
      if (actualCategory === undefined) {
        actualCategory = templateTypes.find(
          (category) => category.value === selectedCategory
        );
      }
    });

    return actualCategory ? actualCategory.value : '';
  }

  handleFilterChanged = (name, value) => {
    const {
      contentType,
      frameRates: currentFrameRates,
      categories: currentCategories,
    } = this.props.selectedSearchFilterOptions;
    const contentTypeDidChange =
      name === 'contentType' && value !== contentType;
    const newContentTypeIsTemplates =
      contentTypeDidChange && value === 'templates';

    const updateFields: Partial<VideoSelectedSearchFilterOptions> = {
      page: 1, // Go back to page 1 when any filter is changed
      isPagination: false,
      searchOrigin: searchOrigins.FILTERS,
    };

    // Adds or removes the selected frame rate from the frame rate filter list
    // if frame rate filter was changed. Else, update name:value pair.
    if (name.substring(0, 3) === 'fps') {
      updateFields.frameRates = value
        ? [...currentFrameRates, name]
        : currentFrameRates.filter(
            (changedFrameRate) => changedFrameRate !== name
          );
    } else {
      updateFields[name] = value;
    }

    if (name === 'contentType') {
      updateFields.contentType = value;

      // Reassert the content class in case the NavSearchContainer selection has changed
      updateFields.contentClass =
        SearchFilterOptions.getContentClassFromContentType(value);
    }

    if (contentTypeDidChange) {
      updateFields.categories = '';
      updateFields.templateType = '';
      updateFields.minDuration = null;
      updateFields.maxDuration = null;
      updateFields.frameRates = [];
    }

    if (newContentTypeIsTemplates) {
      updateFields.videoQuality = VideoQualityType.HD;
    }

    if (name === 'templateType') {
      updateFields.categories = [keepOnlyCategoryIds(currentCategories), value]
        .filter(Boolean)
        .join(',');
    }

    if (name === 'usageRights') {
      updateFields.usageRights = value;
    }

    if (contentType) {
      updateFields.contentClass =
        SearchFilterOptions.getContentClassFromContentType(contentType);
    }

    const newSearchOptions =
      this.props.selectedSearchFilterOptions.update(updateFields);

    this.collapseDrawerIfNecessary();
    this.setState((state) => ({
      collapsed: {
        ...state.collapsed,
        categories: false,
      },
    }));

    this.props.updateSearchOptionsAndRefresh(newSearchOptions);
  };

  collapseDrawerIfNecessary() {
    this.props.collapseMoreLikeThisDrawer();
  }

  clearFilters = (e) => {
    e.preventDefault();

    let newSearchOptions = SearchOptions.getDefaultSearchOptions();
    newSearchOptions = newSearchOptions.update({
      searchTerm: this.props.selectedSearchFilterOptions.searchTerm,
    });

    this.props.updateSearchOptionsAndRefresh(newSearchOptions);
  };

  toggleCollapsed(name) {
    const collapsed = this.state.collapsed;
    collapsed[name] = !collapsed[name];

    this.setState({ collapsed });
  }
}

export function mapStateToProps(state) {
  return {
    selectedSearchFilterOptions: selectSearchFilterOptions(state),
    shouldUpdateSearchFilters: selectShouldUpdateSearchFilters(state),
    features: selectSearchFeatures(state),
  };
}

export default connect(mapStateToProps, {
  updateSearchOptionsAndRefresh,
  collapseMoreLikeThisDrawer,
})(VideoMenuContainer);
